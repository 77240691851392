import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import Grid from '../components/partials/grid'
import Card from '../components/partials/card'

const ClientPage = ({ data }) => {
	const portfolio = data.strapi.portfolioPage
	const client = data.strapi.client

	return (
		<Layout page={portfolio} metadata={portfolio.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-40 lg:py-80">
					<h1 className="w-10/12 pb-20 xl:pb-40">
						Projects for {client.title}
					</h1>
				</section>

				{/* Small Project Cards in Order */}
				<section className="pb-40 sm:pb-60 md:pb-80 lg:pb-100 xl:pb-120">
					<Grid>
						{client.projects.map(project => {
							return (
								<div key={`project_card_small_${project.slug}`}>
									<Card project={project} />
								</div>
							)
						})}
					</Grid>
				</section>
			</div>
		</Layout>
	)
}

ClientPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			portfolioPage: PropTypes.shape({
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
			client: PropTypes.shape({
				title: PropTypes.string.isRequired,
				projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	query Clients($id: ID!) {
		strapi {
			client(id: $id, publicationState: PREVIEW) {
				slug
				title
				projects {
					slug
					title
					subtitle
					thumbnail {
						url
						ext
						height
						width
						name
						imageFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									width: 360
									height: 270
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
			portfolioPage {
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default ClientPage
